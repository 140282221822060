/* 1366 - 768 */

.charge {
  background: url(images/top-img.png) no-repeat center top;
  background-size: cover;
  min-height: 1180px;
  margin: 0 0 0 0;
  position: relative;
  z-index: 2;

  @include fr-media-down(xl) {
    min-height: 1000px;
  }

  @include fr-media-down(md) {
    min-height: 860px;
  }

  @include fr-media-down(sm) {
    background-image: url(images/top-img-2.png);
  }

  .container {
    max-width: 1648px;

    @include fr-media-down(xl) {
      max-width: 992px;
    }

    @include fr-media-down(lg) {
      max-width: 768px;
    }

    @include fr-media-down(md) {
      max-width: 576px;
    }
  }

  &__image {
    @include fr-flex-col-custom(50%);
    position: relative;

    @include fr-media-down(xl) {
      @include fr-flex-col-custom(40%);
    }

    @include fr-media-down(lg) {
      @include fr-flex-col-custom(50%);
    }

    @include fr-media-down(md) {
      order: 1;
      @include fr-flex-col-custom(100%);
    }

    img {
      position: absolute;
      top: -120px;
      left: -67px;
      //left: -132px;

      left: -283px;
      width: 1130px;
      max-width: none;

      @include fr-media-down(1648px) {
        left: -283px;
        width: 1130px;
      }

      @include fr-media-down(xl) {
        /*width: 612px;
        max-width: none;
        top: -62px;
        left: -124px;*/

        width: 888px;
        top: -73px;
        left: -315px;
      }

      @include fr-media-down(lg) {
        width: 888px;
        top: -15px;
        left: -372px;
      }

      @include fr-media-down(md) {
        width: 487px;
        max-width: none;
        //top: -169px;
        left: -97px;
        top: -42px;
      }

      @include fr-media-down(sm) {
        left: -183px;
        top: -194px;
      }
    }

    &-min {
      display: none;

      @include fr-media-down(sm) {
        display: block;
      }
    }

    &-full {
      @include fr-media-down(sm) {
        display: none;
      }
    }

  }

  &__content {
    @include fr-flex-col-custom(50%);
    padding-right: 15px;
    position: relative;
    z-index: 3;

    @include fr-media-down(xl) {
      @include fr-flex-col-custom(60%);
    }

    @include fr-media-down(lg) {
      @include fr-flex-col-custom(50%);
    }

    @include fr-media-down(md) {
      order: 0;
      @include fr-flex-col-custom(100%);
      padding: 0 15px;
    }
  }

  &__title {
    padding: 270px 0 60px 0;
    font-weight: 800;
    font-size: 48px;
    text-transform: uppercase;
    color: #ffffff;
    line-height: 71px;
    text-shadow: 2px 6px 4px rgba(1, 1, 1, 0.17);
    letter-spacing: 0.92px;

    @include fr-media-down(xl) {
      font-size: 41px;
      line-height: 60px;
      font-size: 29px;
      line-height: 43px;
      padding: 220px 0 10px 0;
    }

    @include fr-media-down(lg) {
      font-size: 29px;
      line-height: 43px;
      letter-spacing: 0.9px;
      padding-bottom: 40px;
      padding-top: 250px;
    }

    @include fr-media-down(sm) {
      font-size: 21px;
      line-height: 36px;
      letter-spacing: 0.68px;
      padding: 190px 0 30px 0;
    }
  }

  &__text {
    font-size: 31px;
    line-height: 41px;
    font-weight: 500;
    color: #ffffff;

    @include fr-media-down(xl) {
      font-size: 26px;
      line-height: 35px;
      font-size: 18px;
      line-height: 25px;
    }

    @include fr-media-down(lg) {
      font-size: 18px;
      line-height: 25px;
    }

    @include fr-media-down(sm) {
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__buttons {
    display: flex;
    max-width: 660px;
    justify-content: space-between;
    align-items: center;
    padding: 80px 0 0 0;

    @include fr-media-down(lg) {
      padding-top: 45px;
      flex-wrap: wrap;
    }

    @include fr-media-down(sm) {
      padding-top: 70px;
    }
  }

  &__btn-presentation {
    @include fr-button(330px, 60px, 16px, 700);
    background: #ffffff;
    color: #2a0d71;
    padding: 0 20px 0 60px;
    margin-right: 20px;

    @include fr-media-down(xl) {
      font-size: 14px;
      height: 53px;
      background-size: 26px 26px;
      width: 285px;
    }

    @include fr-media-down(lg) {
      width: 285px;
      margin: 0 0 30px 0;
      justify-content: flex-start;
      padding-left: 76px;
    }

    @include fr-media-down(sm) {
      font-size: 13px;
      height: 50px;
      background-size: 25px 25px;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 25px;
      transform: translateY(-50%);
      background: url(images/icon-presentation.png) no-repeat center center;
      width: 31px;
      height: 31px;
      z-index: 2;

      @include fr-media-down(xl) {
        width: 26px;
        height: 26px;
        background-size: 26px 26px;
      }

      @include fr-media-down(sm) {
        width: 25px;
        height: 25px;
        background-size: 25px 25px;
      }
    }

    &:after {
      background: #ffffff;
    }

    span {
      color: #2a0d71;

      @include fr-media-down(xl) {
        font-size: 14px;
      }
      @include fr-media-down(sm) {
        font-size: 13px;
      }
    }

    &:hover {
      color: #2a0d71;

      span {
        color: #2a0d71;
      }
    }
  }

  &__btn-video {
    @include fr-button(268px, 60px, 16px, 700);
    background: #ffffff;
    color: #2a0d71;
    padding: 0 20px 0 60px;

    @include fr-media-down(xl) {
      font-size: 14px;
      height: 53px;
      background-size: 25px 24px;
      width: 220px;
    }

    @include fr-media-down(lg) {
      width: 285px;
      max-width: 285px;
      justify-content: flex-start;
      padding-left: 76px;
    }

    @include fr-media-down(sm) {
      font-size: 13px;
      height: 50px;
      background-size: 25px 24px;
    }

    &:before {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      left: 25px;
      transform: translateY(-50%);
      background: url(images/icon-video.png) no-repeat center center;
      width: 30px;
      height: 28px;
      z-index: 2;

      @include fr-media-down(xl) {
        width: 25px;
        height: 24px;
        background-size: 25px 24px;
      }

      @include fr-media-down(sm) {
        width: 25px;
        height: 24px;
        background-size: 25px 24px;
      }
    }

    &:after {
      background: #ffffff;
    }

    span {
      color: #2a0d71;

      @include fr-media-down(xl) {
        font-size: 14px;
      }
      @include fr-media-down(sm) {
        font-size: 13px;
      }
    }

    &:hover {
      color: #2a0d71;

      span {
        color: #2a0d71;
      }
    }
  }
}


.preload3d {
  position:relative;
  width: 0px;
  height: 0px;
  overflow: hidden;

  /*&-inner {
    opacity:1;
    position: absolute;
    top: 0;
    left: 0;
  }*/

  img {
    position: absolute;
    top: 0;
    left: 0;
  }
}
