.what-else {
  //background: #2b0d73;
  padding: 140px 0 205px 0;
  background: url(images/schema-img.png) no-repeat left -220px;

  @include fr-media-down(md) {
    background-image: none;
  }

  &__title {
    font-size: 36px;
    line-height: 48px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    padding: 10px 0 85px 0;

    @include fr-media-down(md) {
      font-size: 26px;
      line-height: 32px;
    }

    @include fr-media-down(sm) {
      font-size: 22px;
      line-height: 36px;
      padding: 0 0 50px 0;
    }
  }

  &__text {
    &-row {
      display: flex;
      padding: 55px 0 60px 0;
    }

    &-col {
      font-size: 30px;
      line-height: 37px;
      font-weight: 700;
      text-align: center;
      @include fr-flex-col(6);
      color: #ffffff;
    }
  }


  /*&__row {
    display: flex;
    margin: 0 -10px;
  }

  &__col {
    @include fr-flex-col(3);
    padding: 0 10px;

    &-image {
      text-align: center;

      img {
        max-width: 100%;
      }
    }

    &-text {
      font-size: 24px;
      line-height: 27px;
      color: #8075b1;
      font-weight: 700;
      text-align: center;
    }
  }*/

  &__row {
    display: flex;
    margin: 0 -46px;
    margin: 0 -15px;

    @include fr-media-down(lg) {
      margin: 0;
      flex-wrap: wrap;
    }
  }

  &__col {
    @include fr-flex-col(6);
    padding: 0 46px;
    padding: 0 15px;

    @include fr-media-down(xl) {
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }

    @include fr-media-down(lg) {
      @include fr-flex-col(12);
      padding: 0;
    }
  }

  &__item {
    //background: url(images/what-img-1.png);
    background-size: 100% auto;
    height: 566px;
    border-radius: 50px;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    max-width: 598px;
    transition: box-shadow .3s ease;

    @include fr-media-down(xl) {
      height: 475px;
    }

    @include fr-media-down(lg) {
      max-width: 610px;
      height: 582px;
      margin: 0 auto;
    }

    @include fr-media-down(md) {
      height: 465px;
    }

    @include fr-media-down(sm) {
      height: 700px;
      background-size: auto 305px;
      background-position: center top;
      max-width: 340px;
      margin: 0 auto;
    }

    &:hover {
      box-shadow: 0px 0px 40.47px 16.53px rgba(112, 0, 255, 0.32);
      transition: box-shadow .3s ease;
    }

    &--it1 {
      margin: 0 auto 0 0;
      //background-image: url(images/what-img-1.jpg);

      @include fr-media-down(lg) {
        margin: 0 auto 42px auto;
      }

      .what-else__inner {
        background-image: url(images/what-img-bg-1.png);

        @include fr-media-down(lg) {
          background-image: url(images/what-img-bg-1-768.png);
        }
      }

      .what-else__inner-image {
        background-color: #2b0d72;

        img {
          width: 50px;

          @include fr-media-down(xl) {
            width: 42px;
          }

          @include fr-media-down(lg) {
            width: 50px;
          }

          @include fr-media-down(md) {
            width: 42px;
          }
        }
      }
    }

    &--it2 {
      margin: 0 0 0 auto;
      //background-image: url(images/what-img-2.jpg);

      @include fr-media-down(lg) {
        margin: 0 auto;
      }

      .what-else__inner {
        background-image: url(images/what-img-bg-2.png);

        @include fr-media-down(lg) {
          background-image: url(images/what-img-bg-2-768.png);
        }
      }

      .what-else__inner-image {
        background-color: #dc6048;

        img {
          width: 47px;

          @include fr-media-down(xl) {
            width: 39px;
          }

          @include fr-media-down(lg) {
            width: 47px;
          }

          @include fr-media-down(md) {
            width: 39px;
          }
        }
      }
    }
  }

  &__inner {
    height: 351px;
    //background: url(images/what-img-bg-1.png) no-repeat center center;
    @include fr-background-cover();
    background-position: top center;
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    padding: 122px 65px 0 65px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    text-align: left;
    letter-spacing: 0.18px;

    @include fr-media-down(xl) {
      font-size: 15px;
      line-height: 21px;
      padding: 100px 52px 0 52px;
      height: 292px;
      background-size: 481px 292px;
    }

    @include fr-media-down(lg) {
      background-size: cover;
      height: 357px;
      font-size: 18px;
      line-height: 26px;
      padding: 120px 65px 0 65px;
    }

    @include fr-media-down(md) {
      font-size: 15px;
      line-height: 21px;
      height: 298px;
      padding: 100px 65px 0 65px;
    }

    @include fr-media-down(sm) {
      font-size: 16px;
      line-height: 26px;
      height: 470px;
      padding: 120px 35px 0 35px;
    }

    &-title {
      color: #ffffff;
      font-size: 24px;
      font-weight: 500;
      line-height: 37px;
      text-align: left;
      padding: 0 0 20px 0;

      @include fr-media-down(xl) {
        font-size: 20px;
        line-height: 31px;
      }

      @include fr-media-down(lg) {
        font-size: 25px;
        line-height: 38px;
      }

      @include fr-media-down(md) {
        font-size: 20px;
        line-height: 31px;
      }

      @include fr-media-down(sm) {
        font-size: 25px;
        line-height: 38px;
      }
    }

    &-image {
      width: 86px;
      height: 86px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 9px;
      left: 59px;

      @include fr-media-down(xl) {
        left: 52px;
        width: 73px;
        height: 73px;
      }

      @include fr-media-down(lg) {
        left: 65px;
        width: 88px;
        height: 88px;
      }

      @include fr-media-down(md) {
        left: 65px;
        width: 73px;
        height: 73px;
      }

      @include fr-media-down(sm) {
        left: 35px;
        width: 88px;
        height: 88px;
      }
    }
  }





}
