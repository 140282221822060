.form {
  &__wrap {
    position: relative;

    &.loading {
      &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: -10px;
        left: -10px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        background: #3c1fba;
        opacity: .5;
      }
    }
  }

  &__form {
    position: relative;

    &.loading {
      &:after {
        content: '';
        display: block;
        position: absolute;
        z-index: 1;
        top: -10px;
        left: -10px;
        width: calc(100% + 20px);
        height: calc(100% + 20px);
        background: #3c1fba;
        opacity: .5;
      }
    }
  }

  &__result {
    /*position: absolute;
    z-index: 1;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    background: #3c1fba;*/
    display: none;
    padding: 50px 0 70px 0;

    &-inner {
      height: 100%;
      font-weight: 400;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: .05em;
      color: #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}