.video {
  padding: 115px 0 60px 0;
  background: url(images/video-bg.png) no-repeat 0 center;

  @include fr-media-down(xl) {
    background-size: 256px auto;
    background-position: left bottom 10px;
  }

  @include fr-media-down(sm) {
    background-image: none;
    padding-bottom: 52px;
  }

  &__title {
    font-size: 36px;
    line-height: 48px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    padding: 0 0 90px 0;

    @include fr-media-down(lg) {
      font-size: 30px;
      line-height: 36px;
    }

    @include fr-media-down(md) {
      font-size: 26px;
      line-height: 32px;
      padding: 0 0 50px 0;
    }

    @include fr-media-down(sm) {
      font-size: 22px;
      line-height: 36px;
      padding: 0 0 50px 0;
    }
  }

  &__block {
    padding: 0 0 45px 45px;
    position: relative;

    @include fr-media-down(xl) {
      padding: 0 0 36px 36px;
    }

    @include fr-media-down(lg) {
      padding: 0 0 26px 26px;
    }

    @include fr-media-down(md) {
      padding: 0 0 16px 16px;
    }

    @include fr-media-down(sm) {
      padding: 0 0 16px 0;
      margin: 0 -15px;
    }

    &:after {
      content: '';
      display: block;
      width: calc(100% - 45px);
      height: calc(100% - 45px);
      position: absolute;
      top: 45px;
      left: 0;
      border: 3px solid #4800A9;
      border-radius: 50px;

      @include fr-media-down(xl) {
        width: calc(100% - 36px);
        height: calc(100% - 36px);
        top: 36px;
      }

      @include fr-media-down(lg) {
        width: calc(100% - 26px);
        height: calc(100% - 26px);
        top: 26px;
      }

      @include fr-media-down(md) {
        width: calc(100% - 16px);
        height: calc(100% - 16px);
        top: 16px;
      }

      /*background-image: linear-gradient(81deg, rgba(114, 0, 255, 0.98) 0%, rgba(72, 0, 169, 0.98) 45%, rgba(65, 0, 157, 0.98) 54%, rgba(48, 0, 127, 0.98) 77%, rgba(55, 0, 112, 0.98) 100%);
      opacity: 0.51;
      z-index: 1;*/
    }

    &:before {
      content: '';
      display: block;
      background-color: #211260;
      width: calc(100% - 51px);
      height: calc(100% - 51px);
      position: absolute;
      top: 48px;
      left: 3px;
      border-radius: 50px;
      z-index: 2;
      opacity: 0.51;
      display: none;
    }
  }

  &__inner {
    border-radius: 50px;
    overflow: hidden;
    background: url(images/video.png) no-repeat center;
    background-size: cover;
    height: 710px;
    position: relative;
    z-index: 2;

    @include fr-media-down(xl) {
      height: 547px;
    }

    @include fr-media-down(lg) {
      height: 397px;
    }

    @include fr-media-down(md) {
      height: 350px;
    }

    @include fr-media-down(sm) {
      height: 320px;
      border-radius: 0;
    }

    @include fr-media-down(440px) {
      height: 200px;
      border-radius: 0;
    }
  }

  &__button {
    @include fr-button(216px);
    margin: 60px auto 0 auto;
    position: relative;
    z-index: 5;
  }

  .video-js {
    width: 100%;

    .vjs-big-play-button {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 136px;
      height: 136px;
      background: url(images/play.png) no-repeat center;
      background-size: cover;
      border: 0;

      @include fr-media-down(xl) {
        width: 105px;
        height: 105px;
      }

      @include fr-media-down(lg) {
        width: 76px;
        height: 76px;
      }

      @include fr-media-down(sm) {
        width: 37px;
        height: 37px;
      }

      span {
        display: none;
      }
    }
  }

  .vjs-poster {
    background-size: cover;
  }

  .my-video-dimensions {
    @include fr-media-down(xl) {
      height: 547px;
    }
    @include fr-media-down(lg) {
      height: 397px;
    }

    @include fr-media-down(md) {
      height: 350px;
    }

    @include fr-media-down(sm) {
      height: 320px;
    }

    @include fr-media-down(440px) {
      height: 200px;
    }
  }
}

