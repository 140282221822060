.compare {
  background-color: #350584;
  padding: 0 0 100px 0;
  position: relative;

  &__wave-1 {
    height: 150px;
    width: 100%;
    min-width: 1200px;
    position: absolute;
    top: -147px;
    left: 0;
    background: url(images/wave-3.svg) no-repeat center;
    background-size: 100% 150px;
  }


  .col-xl-6.col-lg-6.col-md-6.col-sm-6 {
    @include fr-media-down(sm) {
      &:last-child {
        padding-top: 50px;
      }
    }
  }

  &__title {
    font-size: 36px;
    line-height: 48px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    padding: 0 0 85px 0;

    @include fr-media-down(lg) {
      font-size: 30px;
      line-height: 36px;
      padding: 0 0 60px 0;
    }

    @include fr-media-down(md) {
      font-size: 26px;
      line-height: 32px;
    }

    @include fr-media-down(sm) {
      font-size: 22px;
      line-height: 36px;
      padding: 0 0 50px 0;
      text-align: left;
    }
  }


  .row {
    align-items: flex-start;
  }

  &__item {
    display: flex;

    @include fr-media-down(xl) {
      flex-wrap: wrap;
    }

    @include fr-media-down(sm) {

    }

    &:hover {
      .compare__item-image-default {

        opacity: 0;
        visibility: hidden;
        transition: opacity 0.3s ease, visibility .3s ease;
      }

      .compare__item-image-hover {

        opacity: 1;
        visibility: visible;
        transition: opacity .3s ease, visibility .3s ease;
      }
    }

    &-image {
      position: relative;
      @include fr-flex-col-auto();
      width: 350px;
      height: 350px;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 350px;
      }

      @include fr-media-down(xl) {
        width: 100%;

        img {
          //margin-left: -15px;
        }
      }

      @include fr-media-down(sm) {
        width: 100%;
        max-width: none;
        margin: 0 -15px;
        margin: 0;

        img {
          margin: 0 auto;
          //max-width: 332px;
        }
      }

      &--item-1 {
        @include fr-media-down(md) {
          img {
            margin-left: -15px;
          }
        }

        @include fr-media-down(sm) {
          img {
            margin-left: 0;
          }
        }
      }

      &--item-2 {
        @include fr-media-down(md) {
          img {
            margin-right: -15px;
          }
        }

        @include fr-media-down(sm) {
          img {
            margin-right: 0;
          }
        }
      }

      &-default {
        z-index: 2;
        opacity: 1;
        visibility: visible;
        transition: opacity .3s ease, visibility .3s ease;
      }

      &-hover {
        z-index: 1;
        opacity: 0;
        visibility: hidden;
        transition: opacity .3s ease, visibility .3s ease;
      }
    }

    &-content {
      @include fr-flex-col-100();
      padding: 20px 0 0 5px;

      @include fr-media-down(xl) {
        padding-top: 0;
      }

      @include fr-media-down(sm) {
        @include fr-flex-col(12);
      }
    }

    &-title {
      color: #ffffff;
      font-size: 25px;
      font-weight: 500;
      line-height: 38px;
      text-align: left;
      padding: 0 0 40px 0;

      @include fr-media-down(lg) {
        padding: 0 0 30px 0;
      }

      @include fr-media-down(md) {
        font-size: 22px;
        line-height: 34px;
        padding: 0 0 20px 0;
      }

      @include fr-media-down(sm) {
        font-size: 25px;
        line-height: 38px;
      }
    }

    &-text {
      opacity: 0.6;
      color: #ffffff;
      font-size: 17px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0.42px;

      @include fr-media-down(xl) {
        max-width: 410px;
      }

      @include fr-media-down(md) {
        font-size: 15px;
        line-height: 24px;
      }

      @include fr-media-down(sm) {
        font-size: 17px;
        line-height: 27px;
        max-width: 100%;
      }
    }
  }


}
