html {
  font: #{$fr-root-font-size}/#{$fr-line-height} $fr-font;
  font-weight: 400;
  min-height: 100%;
  height: auto;
}

body {
  color: $fr-text-color;
  font-size: $fr-font-size;
  font-family: $fr-font;
  font-weight: 400;
  height: auto;
  background-color: #ffffff;
  position: relative;
  //min-width: 1262px;
}

a {
  color: #583416;
  &:hover {
    text-decoration: none;
  }
}


.container {
  min-width: 320px;
}


::placeholder {
  opacity: 1;
  color: #2a0d71;
}
:-ms-input-placeholder {
  opacity: 1;
  color: #2a0d71;
}
::-ms-input-placeholder {
  opacity: 1;
  color: #2a0d71;
}


h2 {
  color: #ffffff;
  font-size: 30px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.75px;
  position: relative;
  padding: 0 0 40px 0;
  margin: 0 0 35px 0;

  &:after {
    content: '';
    display: block;
    width: 45px;
    height: 3px;
    background-color: #fce300;
    position: absolute;
    left: 0;
    bottom: 0;
  }
}



.grid-container {
  height: 100%;
  width: 100%;
  display: grid;

  > * { align-self: center }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}


.container {
  min-width: 320px;
  padding: 0 15px;
  margin: 0 auto;
  //min-width: 1262px;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  align-items: center;
}

.col {
  padding: 0 15px;
}



.main {
  margin: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
.header,
.footer {
  flex-shrink: 0;
  background-color: #ffffff;
}

.content {
  flex: 1 0 auto;
  overflow: hidden;
  padding: 0 0 0 0;
  margin-top: -122px;
  background-color: #211260;
  background-image: radial-gradient(circle 4457px at 64.03% 233.68%, rgba(114, 0, 255, 0.25) 0%, rgba(72, 0, 169, 0.25) 45%, rgba(65, 0, 157, 0.25) 54%, rgba(48, 0, 127, 0.25) 77%, rgba(55, 0, 112, 0.25) 100%);

  @include fr-media-down(lg) {
    margin-top: -193px;
  }

  @include fr-media-down(md) {
    margin-top: -175px;
  }

  @include fr-media-down(sm) {
    margin-top: -122px;
  }
}


.slick-dotted.slick-slider {
  margin-bottom: 0;
}


.main-content {
  padding: 50px 0 70px 0;
}



