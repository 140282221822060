.modal {
  .modal-dialog {
    max-width: 630px;

    @include fr-media-down(lg) {
      max-width: 570px;
    }

    @include fr-media-down(md) {
      max-width: 540px;
    }
  }

  &#getTheCalculationForTheGame {
    .modal-dialog {
      max-width: 692px;

      @include fr-media-down(lg) {
        max-width: 624px;
      }

      @include fr-media-down(md) {
        max-width: 540px;
      }
    }
  }

  .modal-content {
    border: 0;
    border-radius: 50px;
    background-color: #3c1fba;
  }

  .modal-header {
    border: 0;
    padding-left: 20px;
    padding-right: 20px;
    flex-wrap: wrap;
    min-height: 40px;
    position: relative;

    border: 0;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    padding-bottom: 20px;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    min-height: 40px;
    position: relative;
    color: #FFF;
  }

  .close {
    font-size: 0;
    font-weight: 400;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    position: absolute;
    color: #000;
    top: 40px;
    right: 40px;
    opacity: 1;
    background: url(images/close.png) no-repeat center;
    background-size: auto;
    width: 21px;
    height: 21px;
    background-size: 21px;
    padding: 0;

    @include fr-media-down(sm) {
      top: 27px;
      right: 32px;
    }
  }

  .modal-title {
    margin: 0 auto;
    font-size: 36px;
    line-height: 1.5;
    font-weight: 900;

    @include fr-media-down(lg) {
      font-size: 32px;
      line-height: 43px;
    }

    @include fr-media-down(sm) {
      font-size: 22px;
      line-height: 34px;
    }
  }

  .modal-footer {
    padding-top: 0;
    padding-right: 65px;
    padding-left: 65px;
    padding-bottom: 45px;
    border: 0;
  }

  .modal-body {
    padding: 30px 65px 45px 65px;

    @include fr-media-down(md) {
      padding: 30px 50px 45px 50px;
    }

    @include fr-media-down(sm) {
      padding: 10px 15px 30px 15px;
    }
  }

  .title {
    color: #ffffff;
    font-size: 30px;
    font-weight: 500;
    line-height: 30px;
    padding: 0 0 15px 0;
    text-align: center;
  }

  .text {
    color: #b7b7b7;
    font-size: 17px;
    font-weight: 300;
    line-height: 18px;
    padding: 0 0 25px 0;
    text-align: center;
    position: relative;
    margin: 0 0 40px 0;

    &:before {
      content: '';
      width: 45px;
      height: 3px;
      background-color: #fce300;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      display: block;
    }
  }

  input[type="text"], textarea {
    width: 100%;
    background: #fff;
    border-radius: 30px;
    color: #2a0d71;
    font-size: 16px;
    line-height: 39px;
    padding: 0 20px;
    height: 60px;
    border: 1px solid #fff;
    outline: none;
    -webkit-transition: opacity 300ms ease, color 300ms ease;
    -o-transition: opacity 300ms ease, color 300ms ease;
    transition: opacity 300ms ease, color 300ms ease;
    margin: 0 0 20px 0;
    text-align: left;
    padding-left: 35px;
    font-weight: 500;

    @include fr-media-down(lg) {
      height: 52px;
      font-size: 14px;
      padding-left: 32px;
    }

    @include fr-media-down(md) {
      padding-left: 25px;
    }

    @include fr-media-down(sm) {
      height: 48px;
    }

    &:focus {
      outline: none;
      color: #2a0d71;
      -webkit-transition: opacity 300ms ease, color 300ms ease;
      -o-transition: opacity 300ms ease, color 300ms ease;
      transition: opacity 300ms ease, color 300ms ease;
    }
  }

  textarea {
    height: 162px;
    padding-top: 15px;

    @include fr-media-down(lg) {
      padding-top: 10px;
    }

    @include fr-media-down(sm) {
      height: 140px;
      padding-top: 5px;
    }
  }


  .button__submit {
    @include fr-button(180px);
    margin: 30px auto 0 auto;
    display: block;

    @include fr-media-down(lg) {
      font-size: 14px;
      height: 52px;
    }

    @include fr-media-down(sm) {
      margin: 10px auto 0 auto;
    }
  }

  .agree {
    color: #b7b7b7;
    font-size: 14px;
    font-weight: 300;
    line-height: 16px;
    text-align: center;
    padding: 25px 0 0 0;
  }

  .error {
    width: 100%;
    overflow: hidden;
    font-size: 14px;
    color: #ff6872;
    padding: 20px 0 0 0;
    text-align: center;
  }

  .result {
    padding: 50px 0 20px 0;
    color: #ffffff;
    font-size: 17px;
    line-height: 24px;
    display: none;
    text-align: center;
  }


  .input-error {
    border: 1px solid #fff !important;
  }

  .help-block {
    position: absolute;
    bottom: 6px;
    left: 35px;
    font-size: 12px;
    color: #ffffff;
    line-height: 12px;

    @include fr-media-down(lg) {
      font-size: 11px;
      line-height: 11px;
      left: 32px;
    }

    @include fr-media-down(md) {
      left: 25px;
    }
  }


  .form__checkbox {
    line-height: 25px;
    position: relative;
    padding: 35px 0 0 0;

    @include fr-media-down(sm) {
      padding-top: 20px;
    }

    .help-block {
      bottom: -14px;
      left: 29px;
    }

    input {
      display: none;
    }

    label {
      padding-left: 30px;
      cursor: pointer;
      position: relative;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      margin: 0;
      color: #ffffff;

      @include fr-media-down(lg) {
        font-size: 12px;
        line-height: 16px;
      }

      a {
        text-decoration: underline;
        color: #ffffff;
      }

      &:before {
        content: "";
        display: inline-block;
        position: absolute;
        top: 2px;
        left: 0;
        font-size: 20px;
        background: transparent;
        border-radius: 50%;

        width: 13px;
        height: 13px;
        border: 2px solid #ffc918;
      }

      &:after {
        content: "";
        width: 14px;
        height: 8px;
        position: absolute;
        top: 2px;
        left: 2px;
        border: 2px solid #ffffff;
        border-top: none;
        border-right: none;
        transform: rotate(-45deg);
        display: none;
      }
    }

    input:checked + label:after {
      //display: block;
    }

    input:checked + label:before {
      //background: $sfx-hover-green;
      //border-color: $sfx-hover-green;
      background: #ffc918;
    }
  }



  .form__fields-inline {
    display: flex;
    align-items: center;
    padding: 15px 0 35px 0;

    &-text {
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      line-height: 17px;
      padding: 0 25px 0 0;

      @include fr-media-down(lg) {
        font-size: 14px;
      }

      @include fr-media-down(sm) {
        padding: 0 20px 0 0;
      }
    }

    .form__checkbox {
      padding: 0;

      &:last-child {
        padding: 0 0 0 55px;

        @include fr-media-down(sm) {
          padding: 0 0 0 35px;
        }
      }
    }
  }

  .form {
    &__row {
      display: flex;
      position: relative;

      @include fr-media-down(sm) {
        flex-wrap: wrap;
      }
    }

    &__col {
      @include fr-flex-col(6);

      @include fr-media-down(sm) {
        @include fr-flex-col(12);
      }

      &:last-child {
        padding-left: 15px;

        @include fr-media-down(sm) {
          padding-left: 0;
        }
      }
      &:first-child {
        padding-right: 15px;

        @include fr-media-down(sm) {
          padding-right: 0;
        }
      }
    }
  }

}

#promoVideoModal {
  .modal-dialog {
    max-width: 1200px;

    @include fr-media-down(xl) {
      max-width: 992px;
    }
    @include fr-media-down(lg) {
      max-width: 768px;
    }
    @include fr-media-down(md) {
      max-width: 576px;
    }
  }

  .video-js {
    width: 100%;
    height: 660px;

    @include fr-media-down(xl) {
      height: 547px;
    }
    @include fr-media-down(lg) {
      height: 397px;
    }

    @include fr-media-down(md) {
      height: 350px;
    }

    @include fr-media-down(sm) {
      height: 320px;
    }

    @include fr-media-down(440px) {
      height: 200px;
    }

    .vjs-big-play-button {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 136px;
      height: 136px;
      background: url(images/play.png) no-repeat center;
      background-size: cover;
      border: 0;

      @include fr-media-down(xl) {
        width: 105px;
        height: 105px;
      }

      @include fr-media-down(lg) {
        width: 76px;
        height: 76px;
      }

      @include fr-media-down(sm) {
        width: 37px;
        height: 37px;
      }

      span {
        display: none;
      }
    }
  }

  .vjs-poster {
    background-size: cover;
  }

}

