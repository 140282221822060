.header {
  height: 122px;
  background: transparent;
  position: relative;
  z-index: 10;

  @include fr-media-down(lg) {
    padding-top: 35px;
    height: 193px;
  }

  @include fr-media-down(md) {
    height: 175px;
  }

  @include fr-media-down(sm) {
    height: 122px;
  }

  .row {
    align-items: center;
    height: 100%;

    @include fr-media-down(lg) {
      align-items: flex-start;
    }
  }

  .container {
    max-width: 1648px;
    height: 100%;

    @include fr-media-down(xl) {
      max-width: 992px;
    }

    @include fr-media-down(lg) {
      max-width: 768px;
    }

    @include fr-media-down(md) {
      max-width: 576px;
    }
  }

  &__logo {
    color: #ffffff;
    //@include fr-flex-col-auto();
    padding: 0 15px;
    @include fr-flex-col-custom(25%);

    @include fr-media-down(xl) {
      @include fr-flex-col-auto();
      width: 252px;
    }

    @include fr-media-down(md) {
      width: 180px;
    }

    @include fr-media-down(sm) {
      width: 155px;
    }


    &-inner {
      width: 100%;
      padding: 0 0 0 0;
      position: relative;
    }

    &-text-1 {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-transform: uppercase;
    }

    &-text-2 {
      font-size: 38px;
      font-weight: 800;
      line-height: 38px;
      text-transform: uppercase;
    }

    &-img {
      width: 214px;

      @include fr-media-down(lg) {
        width: 185px;
      }
    }
  }


  &__contact {
    //@include fr-flex-col-100();
    @include fr-flex-col-custom(75%);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;

    @include fr-media-down(xl) {
      @include fr-flex-col-100();
    }

    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 1000px;

      @include fr-media-down(xl) {
        max-width: 642px;
      }

      @include fr-media-down(lg) {
        flex-wrap: wrap;
        max-width: 455px;
      }

      @include fr-media-down(sm) {

      }
    }

    &-phone-email {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include fr-media-down(xl) {
        width: 215px;
        flex-direction: column;
        align-items: flex-start;
      }

      @include fr-media-down(lg) {
        @include fr-flex-col-100();
        order: 0;
      }

      @include fr-media-down(sm) {
        position: absolute;
        bottom: -14px;
        left: 0;
        width: 100%;
        padding: 0 15px;
      }
    }
  }

  &__button {
    @include fr-button(180px);

    @include fr-media-down(lg) {
      @include fr-flex-col-auto();
      width: 180px;
      order: 1;
    }

    @include fr-media-down(md) {
      width: 150px;
      font-size: 14px;
      padding: 0 10px;

      span {
        font-size: 14px;
      }
    }

    @include fr-media-down(sm) {
      display: none;
    }
  }

  &__phone, &__email {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    text-decoration: none;
    background-repeat: no-repeat;
    background-position: left;

    @include fr-media-down(md) {
      font-size: 15px;
    }

    @include fr-media-down(sm) {
      font-size: 14px;
    }

    &:hover {
      color: #ffffff;
    }
  }

  &__phone {
    //margin: 0 80px 0 0;
    background-image: url('images/footer_phone.png');
    padding-left: 35px;
    background-size: 16px;

    @include fr-media-down(md) {
      padding-left: 30px;
    }
  }

  &__email {
    //margin: 0 60px 0 0;
    background-image: url('images/footer_mail.png');
    padding-left: 35px;
    background-size: 16px;
    margin-left: 56px;

    @include fr-media-down(xl) {
      margin-left: 0;
      margin-top: 10px;
    }

    @include fr-media-down(md) {
      padding-left: 30px;
      margin-top: 10px;
    }

    @include fr-media-down(sm) {
      margin-top: 5px;
    }
  }

  &__social {
    width: 162px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin: 0 80px 0 0;

    @include fr-media-down(lg) {
      width: 100%;
      order: 2;
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      padding: 35px 0 0 0;
    }

    @include fr-media-down(md) {
      padding: 20px 0 0 0;
    }

    @include fr-media-down(sm) {
      padding-top: 0;
    }

    &-link {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.24);
      display: flex;
      align-items: center;
      justify-content: center;

      @include fr-media-down(lg) {
        width: 50px;
        height: 50px;
      }

      @include fr-media-down(sm) {
        width: 44px;
        height: 44px;
      }

      &--vk {
        background: transparent;
        display: block;
        color: #FFF;
        font-size: 13px;
        //padding: 0 8px;

        @include fr-media-down(lg) {
          margin-right: 23px;
          width: 40px;
        }

        @include fr-media-down(sm) {
          width: 34px;
        }

        img {
          width: 48px;
          top: 2px;
          position: relative;

          @include fr-media-down(lg) {
            width: 40px;
          }

          @include fr-media-down(sm) {
            width: 34px;
          }
        }
      }

      &--inst {
        transition: background-color .3s ease;

        &:hover {
          background: rgba(0, 0, 0, 0.51);
          transition: background-color .3s ease;
        }

        img {
          width: 27px;

          @include fr-media-down(lg) {
            width: 21px;
          }
        }
      }
    }
  }

}
