.slider {
  //background: #211260;
  padding: 0 0 195px 0;

  background: url(images/footer-bg-img.png) no-repeat left bottom #211260;

  @include fr-media-down(xl) {
    background-position: left -350px bottom;
  }

  @include fr-media-down(lg) {
    padding-bottom: 240px;
    background-position: left -500px bottom 40px;
  }

  @include fr-media-down(md) {
    background-image: none;
    padding-bottom: 310px;
  }

  @include fr-media-down(sm) {
    padding-bottom: 285px;
  }

  &__item {
    @include fr-background-cover();
    height: 1175px;

    @include fr-media-down(xl) {
      height: 700px;
    }

    @include fr-media-down(lg) {
      height: 528px;
    }

    @include fr-media-down(sm) {
      height: 320px;
    }

    @include fr-media-down(440px) {
      height: 230px;
    }
  }

  &__button {
    @include fr-button(260px);
    margin: 140px auto 0 auto;
  }

  .slick-dots {
    bottom: -66px;

    li {
      width: 8px;
      height: 8px;
      margin: 0 12px;

      button {
        padding: 0;
        width: 8px;
        height: 8px;
        background-color: #dc6048;
        border-radius: 50%;

        &:before {
          display: none;
        }
      }

      &.slick-active {
        button {
          box-shadow: 0 0 6px 1px rgba(220, 96, 72, 0.91);
        }
      }
    }
  }

  .slick-next {
    width: 89px;
    height: 89px;
    background: url('images/gallery_arrow.png') no-repeat center;
    background-size: cover;
    right: 150px;
    z-index: 3;

    @include fr-media-down(lg) {
      right: 50px;
    }

    @include fr-media-down(md) {
      right: 15px;
      width: 75px;
      height: 75px;
    }

    @include fr-media-down(sm) {
      right: 5px;
      width: 30px;
      height: 30px;
    }

    &:before {
      display: none;
    }
  }

  .slick-prev {
    left: 150px;
    width: 89px;
    height: 89px;
    background: url('images/gallery_arrow_prev.png') no-repeat center;
    background-size: cover;
    z-index: 3;

    @include fr-media-down(lg) {
      left: 50px;
    }

    @include fr-media-down(md) {
      left: 15px;
      width: 75px;
      height: 75px;
    }

    @include fr-media-down(sm) {
      left: 5px;
      width: 30px;
      height: 30px;
    }

    &:before {
      display: none;
    }
  }

}