.control {
  padding: 90px 0 30px 0;
  position: relative;
  z-index: 2;
  background: #ffffff;

  @include fr-media-down(md) {
    padding: 50px 0 30px 0;
  }

  &__wave {
    height: 150px;
    width: 100%;
    min-width: 1300px;
    position: absolute;
    top: -147px;
    left: 0;
    background: url(images/wave.svg) no-repeat center;
    background-size: 100% 150px;

    @include fr-media-down(xl) {
      left: 50%;
      transform: translateX(-50%);
    }

    @include fr-media-down(sm) {
      transform: translateX(-35%);
    }
  }

  &__wave-2 {
    height: 150px;
    width: 100%;
    min-width: 1300px;
    position: absolute;
    bottom: -147px;
    left: 0;
    background: url(images/wave-2.svg) no-repeat center;
    background-size: 100% 150px;

    @include fr-media-down(xl) {
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__title {
    font-size: 36px;
    line-height: 48px;
    font-weight: 700;
    color: #474646;
    text-align: center;
    padding: 0 0 70px 0;

    @include fr-media-down(xl) {
      font-size: 30px;
      line-height: 36px;
    }

    @include fr-media-down(md) {
      font-size: 26px;
      line-height: 32px;
    }

    @include fr-media-down(sm) {
      font-size: 22px;
      line-height: 36px;
      padding: 0 0 50px 0;
    }
  }


  &__button {
    @include fr-button(260px);
    margin: 30px auto 0 auto;
    position: relative;
    z-index: 3;

    @include fr-media-down(md) {
      margin-top: 50px;
    }
  }

  &__content {
    padding: 0 0 0 300px;
    position: relative;

    @include fr-media-down(xl) {
      padding-left: 250px;
    }

    @include fr-media-down(lg) {
      padding-left: 200px;
    }

    @include fr-media-down(md) {
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__image {
    position: relative;

    @include fr-media-down(md) {
      width: 100%;
      order: 0;
    }

    @include fr-media-down(sm) {
      margin: 0 -15px;
      width: auto;
    }

    img {
      max-width: 100%;
    }
  }

  &__content-text {
    @include fr-media-down(md) {
      width: 100%;
      order: 1;
      display: flex;
      flex-wrap: wrap;
      padding: 30px 0 0 0;
      margin: 0 -15px;
    }
  }

  &__item {
    position: absolute;
    max-width: 220px;

    @include fr-media-down(lg) {
      max-width: 200px;
    }

    @include fr-media-down(md) {
      position: static;
      max-width: none;
      @include fr-flex-col(12);
      padding: 0 15px;
    }

    &--c1 {
      top: 85px;
      left: 0px;

      @include fr-media-down(xl) {
        top: 20px;
      }
    }

    &--c2 {
      top: 282px;
      left: 0px;

      @include fr-media-down(xl) {
        top: 210px;
      }

      @include fr-media-down(lg) {
        top: 170px;
      }

      @include fr-media-down(md) {
        padding-top: 30px;
      }
    }

    &--c3 {
      top: 626px;
      left: 0px;

      @include fr-media-down(xl) {
        top: 470px;
      }

      @include fr-media-down(lg) {
        top: 360px;
      }

      @include fr-media-down(md) {
        padding-top: 30px;
      }
    }

    &-title {
      font-size: 30px;
      line-height: 37px;
      font-weight: 700;
      color: #474646;
      text-align: left;
      padding: 0 0 15px 0;

      @include fr-media-down(md) {
        font-size: 26px;
        line-height: 32px;
      }

    }

    &-text {
      font-size: 18px;
      line-height: 24px;
      font-weight: 700;
      color: #665b97;
      text-align: left;

      @include fr-media-down(lg) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }


  .model {
    width: 1400px !important;
    cursor: move;
    position: absolute !important;
    left: -235px;
    top: 0;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    //background: #0d95e8;

    @include fr-media-down(1550px) {
      //left: -240px;
      width: 1300px !important;
    }

    @include fr-media-down(1400px) {
      //left: -225px;
      width: 1300px !important;
      margin-left: -30px;
    }

    @include fr-media-down(xl) {
      //top: -73px;
      width: 1080px !important;
    }

    @include fr-media-down(lg) {
      width: 800px !important;
      margin-left: -30px;
    }

    @include fr-media-down(md) {
      //top: 62px;
      //left: -7px;
      width: 800px !important;
    }

    @include fr-media-down(sm) {
      //top: 170px;
      //left: -16px;
      width: 750px !important;
    }

    @include fr-media-down(510px) {
      width: 650px !important;
    }

    @include fr-media-down(440px) {
      width: 550px !important;
      margin-left: -18px;
    }

    @include fr-media-down(387px) {
      width: 470px !important;
    }

    img {
      max-width: 100%;
    }

    &.hidden {
      display: none;
    }
  }


  &__model {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
  }
}

