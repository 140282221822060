.footer {
  height: 122px;
  //background: url(images/footer-bg.png) no-repeat center center;
  //background-size: cover;
  margin-top: -122px;
  background: transparent;
  position: relative;
  overflow: hidden;

  @include fr-media-down(lg) {
    padding: 35px 0 0 0;
    height: 160px;
    margin-top: -160px;
  }

  @include fr-media-down(md) {
    padding: 35px 0 30px 0;
    height: 244px;
    margin-top: -244px;
  }

  @include fr-media-down(sm) {
    padding: 20px 0 15px 0;
    height: 210px;
    margin-top: -210px;
  }

  &:after {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(81deg, rgba(114, 0, 255, 0.51) 0%, rgba(72, 0, 169, 0.51) 45%, rgba(65, 0, 157, 0.51) 54%, rgba(48, 0, 127, 0.51) 77%, rgba(55, 0, 112, 0.51) 100%);
    opacity: 0.51;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
  }


  .row {
    align-items: center;
    height: 100%;

    @include fr-media-down(lg) {
      align-items: flex-start;
    }

    @include fr-media-down(md) {
      flex-wrap: wrap;
    }
  }

  .container {
    position: relative;
    z-index: 2;
    max-width: 1648px;
    height: 100%;

    @include fr-media-down(xl) {
      max-width: 992px;
    }

    @include fr-media-down(lg) {
      max-width: 768px;
    }

    @include fr-media-down(md) {
      max-width: 576px;
    }
  }

  &__logo {
    color: #ffffff;
    //@include fr-flex-col-auto();
    padding: 0 15px;
    //width: 300px;
    @include fr-flex-col-custom(25%);

    @include fr-media-down(1300px) {
      @include fr-flex-col-custom(22%);
    }

    @include fr-media-down(xl) {
      @include fr-flex-col-auto();
      width: 252px;
    }

    @include fr-media-down(md) {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &-inner {
      width: 252px;
      padding: 0 0 0 0;
      position: relative;

      @include fr-media-down(lg) {
        width: 185px;
      }

      @include fr-media-down(md) {
        width: 180px;
      }

      @include fr-media-down(sm) {
        width: 120px;
        padding-top: 5px;
      }
    }

    &-text-1 {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      text-transform: uppercase;
    }

    &-text-2 {
      font-size: 38px;
      font-weight: 800;
      line-height: 38px;
      text-transform: uppercase;
    }

    &-img {
      width: 57px;
      position: absolute;
      right: 0;
      top: 0;
    }
  }


  &__contact {
    //@include fr-flex-col-100();
    @include fr-flex-col-custom(75%);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;

    @include fr-media-down(1300px) {
      @include fr-flex-col-custom(78%);
    }

    @include fr-media-down(xl) {
      @include fr-flex-col-100();
    }

    @include fr-media-down(md) {
      width: 100%;
      padding: 30px 0 0 0;
    }

    @include fr-media-down(sm) {
      padding-top: 10px;
    }

    &-inner {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      max-width: 1000px;

      @include fr-media-down(xl) {
        max-width: 662px;
      }

      @include fr-media-down(lg) {
        flex-wrap: wrap;
        max-width: 455px;
      }

      @include fr-media-down(md) {
        max-width: 100%;
        padding: 0 15px;
      }
    }

    &-phone-email {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include fr-media-down(xl) {
        width: 215px;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }

  &__button {
    @include fr-button(180px);

    @include fr-media-down(lg) {
      @include fr-flex-col-auto();
      width: 162px;
      font-size: 14px;

      span {
        font-size: 14px;
      }
    }

    @include fr-media-down(md) {
      display: none;
    }
  }

  &__button-moble {
    @include fr-button(180px);
    display: none;

    @include fr-media-down(md) {
      display: flex;
    }

    @include fr-media-down(sm) {
      height: 45px;
      width: 142px;
      font-size: 13px;
      padding: 0 10px;

      span {
        font-size: 13px;
      }
    }
  }


  &__phone, &__email {
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: #ffffff;
    text-decoration: none;

    @include fr-media-down(lg) {
      font-size: 15px;
    }

    @include fr-media-down(sm) {
      font-size: 13px;
    }

    &:hover {
      color: #ffffff;
    }
  }

  &__phone {
    //margin: 0 80px 0 0;
  }

  &__email {
    //margin: 0 60px 0 0;
  }

  &__social {
    //width: 76px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    //margin: 0 60px 0 0;

    @include fr-media-down(lg) {
      @include fr-flex-col-auto();
      width: 50px;
    }

    &-link {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.24);
      display: flex;
      align-items: center;
      justify-content: center;

      @include fr-media-down(lg) {
        width: 50px;
        height: 50px;
      }

      &--vk {
        img {
          width: 39px;
        }
      }

      &--inst {
        transition: background-color .3s ease;

        &:hover {
          background: rgba(0, 0, 0, 0.51);
          transition: background-color .3s ease;
        }

        img {
          width: 27px;

          @include fr-media-down(lg) {
            width: 21px;
          }
        }
      }
    }
  }


  &__phone {
    //margin: 0 80px 0 0;
    background-image: url('images/footer_phone.png');
    background-repeat: no-repeat;
    background-position: left;
    padding-left: 35px;
    background-size: 16px;
  }
  &__email {
    //margin: 0 60px 0 0;
    background-image: url('images/footer_mail.png');
    background-repeat: no-repeat;
    background-position: left;
    padding-left: 35px;
    background-size: 16px;
    margin-left: 35px;

    @include fr-media-down(xl) {
      margin-left: 0;
      margin-top: 10px;
    }

    @include fr-media-down(sm) {
      margin-top: 5px;
    }
  }

  &__vityaz{
    //margin-left: 75px;
    //height: 46px;
    width: 151px;

    @include fr-media-down(lg) {
      width: 91px;
    }
  }

  &__vityaz-link {
    @include fr-media-down(lg) {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-top: 19px;
    }

    @include fr-media-down(md) {
      justify-content: center;
    }
  }

}
