
$fr-font: 'Montserrat', sans-serif;
$fr-root-font-size: 10px;
$fr-font-size: 16px;
$fr-text-color: #474747;
$fr-line-height: 1.6;


@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1367px
);

$container-max-widths: (
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1318px
);

@import "bootstrap/scss/bootstrap";


$fr-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1367px
);

$fr-content-size: (
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1318px
);


:root {
  @each $point, $value in $fr-breakpoints {
    --fr-breakpoint-#{$point}: #{$value};
  }
}


@import "inc/mixins";
@import "inc/reboot";


.container {
  min-width: 320px;
}


@import "slick-carousel/slick/slick.scss";
@import "slick-carousel/slick/slick-theme.scss";

@import "video.js/dist/video-js.min";


@import "inc/font";

@import "inc/base";
@import "inc/header";
@import "inc/footer";
@import "inc/modal";
@import "inc/index";
@import "inc/form";

