.popular {
  background-color: #350584;
  padding: 110px 0 100px 0;

  @include fr-media-down(sm) {
    overflow: hidden;
  }

  &__title {
    font-size: 36px;
    line-height: 48px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    padding: 0 0 35px 0;

    @include fr-media-down(lg) {
      font-size: 30px;
      line-height: 36px;
    }

    @include fr-media-down(md) {
      font-size: 26px;
      line-height: 32px;
    }

    @include fr-media-down(sm) {
      font-size: 22px;
      line-height: 36px;
      padding: 0 0 50px 0;
      text-align: left;
    }
  }


  &__row {
    display: flex;
    margin: 0;
    align-items: flex-end;
    padding: 50px 0 0 0;

    @include fr-media-down(xl) {
      margin: 0 -15px;
    }

    @include fr-media-down(sm) {
      max-width: 340px;
      margin: 0 auto;
      display: block;
      align-items: normal;
    }
  }

  &__wrap {
    @include fr-media-down(sm) {

    }
  }

  .slick-list {
    overflow: visible;
  }

  &__col {
    padding: 0;
    @include fr-flex-col(4);
  }

  &__card {

    @include fr-media-down(sm) {
      max-width: 340px;
      margin: 0 auto;
    }

    &--big {
      height: 628px;
    }

    &--it1 {
      @include fr-media-down(sm) {
        margin: 43px 0 0 0;
      }

      .popular__image {
        height: 261px;

        @include fr-media-down(xl) {
          height: 210px;
        }

        @include fr-media-down(lg) {
          height: 159px;
        }
      }
      .popular__text {
        background-color: #3c1fba;
        box-shadow: 0 0 17px 1px rgba(1, 1, 1, 0.17);
      }
    }

    &--it2 {
      border-radius: 50px;
      box-shadow: 0 0 38px 2px rgba(205, 85, 66, 0.19);

      .popular__image {
        height: 332px;

        @include fr-media-down(xl) {
          height: 268px;
        }

        @include fr-media-down(lg) {
          height: 202px;
        }
      }

      .popular__text {
        background-color: #3c1fba;
        background-image: linear-gradient(-61deg, rgba(245, 167, 152, 0.98) 0%, rgba(220, 96, 72, 0.98) 33%, rgba(117, 35, 37, 0.98) 100%);
      }
    }

    &--it3 {
      @include fr-media-down(sm) {
        margin: 98px 0 0 0;
      }

      .popular__image {
        height: 192px;

        @include fr-media-down(xl) {
          height: 154px;
        }

        @include fr-media-down(lg) {
          height: 104px;
        }
      }

      .popular__text {
        box-shadow: 0 0 17px 1px rgba(1, 1, 1, 0.17);
        background-color: #3c1fba;
      }
    }
  }

  &__image {
    height: 45%;
    background: #ffffff;
    border-radius: 50px 50px 0 0;
    text-align: center;
    position: relative;
    @include fr-background-cover();

    img {
      max-width: 100%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &__text {
    height: 348px;
    border-radius: 0 0 50px 50px;
    background: #3c1fba;
    padding: 50px 15px 20px 15px;

    @include fr-media-down(xl) {
      padding-top: 35px;
      height: 280px;
    }

    @include fr-media-down(lg) {
      height: 315px;
    }

    &-inner {
      padding: 0 30px;

      @include fr-media-down(xl) {
        padding: 0 25px;
      }

      @include fr-media-down(lg) {
        padding: 0 10px;
      }

      @include fr-media-down(md) {
        padding: 0 0px;
      }

      @include fr-media-down(sm) {
        padding: 0 15px;
      }
    }

    &-title {
      color: #ffffff;
      font-size: 30px;
      line-height: 36px;
      font-weight: 500;
      text-align: center;
      padding: 0 0 30px 0;
      letter-spacing: 0.75px;

      @include fr-media-down(xl) {
        font-size: 24px;
        line-height: 30px;
      }

      @include fr-media-down(xl) {
        font-size: 18px;
        line-height: 24px;
      }
    }

    &-item {
      color: #ffffff;
      font-size: 19px;
      line-height: 22px;
      font-weight: 500;
      text-align: left;
      padding: 0 0 18px 42px;
      letter-spacing: 0.43px;
      position: relative;

      @include fr-media-down(xl) {
        font-size: 15px;
        line-height: 18px;
        padding: 0 0 14px 34px;
      }

      @include fr-media-down(lg) {
        padding: 0 0 14px 27px;
      }

      @include fr-media-down(sm) {
        font-size: 16px;
        padding: 0 0 18px 32px;
      }

      &:after {
        content: '';
        width: 24px;
        height: 24px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        background: url(images/compare-plus.png) no-repeat center;
        background-size: cover;

        @include fr-media-down(xl) {
          width: 19px;
          height: 19px;
        }

        @include fr-media-down(lg) {
          width: 15px;
          height: 15px;
          top: 2px;
        }
      }

      &.minus {
        &:after {
          background: url(images/compare-minus.png) no-repeat center;
          background-size: cover;
        }
      }
    }
  }


  .slick-dots {
    bottom: -66px;
    z-index: 3;

    li {
      width: 8px;
      height: 8px;
      margin: 0 12px;

      button {
        padding: 0;
        width: 8px;
        height: 8px;
        background-color: #dc6048;
        border-radius: 50%;

        &:before {
          display: none;
        }
      }

      &.slick-active {
        button {
          box-shadow: 0 0 6px 1px rgba(220, 96, 72, 0.91);
        }
      }
    }
  }
}
