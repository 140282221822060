.properties {
  //background: #2c0d75;
  padding: 340px 0 100px 0;
  position: relative;
  z-index: 1;
  margin-top: -240px;

  background: url(images/prop-after.png) no-repeat left -77px;

  &__item {

  }

  .col-xl-3.col-lg-3.col-md-6 {
    @include fr-media-down(lg) {
      &:nth-child(1), &:nth-child(2) {
        padding-bottom: 60px;
      }
    }

    @include fr-media-down(sm) {
      &:nth-child(1), &:nth-child(2), &:nth-child(3) {
        padding-bottom: 32px;
      }
    }
  }

  &__image {
    width: 91px;
    height: 91px;
    margin: 0 auto;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 17px 1px rgba(1, 1, 1, 0.17);

    &--c1 {
      background: #56e5f9;
    }
    &--c2 {
      background: #6c39ef;
    }
    &--c3 {
      background: #18a7f8;
    }
    &--c4 {
      background: #3c1fba;
    }
  }

  &__text {
    font-weight: 700;
    text-align: center;
    padding: 25px 0 0 0;
    opacity: 0.8;
    color: #ffffff;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.5px;

    @include fr-media-down(xl) {
      font-size: 18px;
    }
  }

}