.emotion {
  background-color: #211260;
  position: relative;

  &__wave {
    height: 150px;
    width: 100%;
    min-width: 1200px;
    position: absolute;
    top: -147px;
    left: 0;
    background: url(images/wave-4.svg) no-repeat center;
    background-size: 100% 150px;
  }

  &__title {
    font-size: 36px;
    line-height: 48px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    padding: 10px 0 85px 0;

    @include fr-media-down(xl) {
      padding-bottom: 65px;
    }

    @include fr-media-down(lg) {
      font-size: 30px;
      line-height: 36px;
    }

    @include fr-media-down(md) {
      font-size: 26px;
      line-height: 32px;
    }

    @include fr-media-down(sm) {
      font-size: 22px;
      line-height: 36px;
    }
  }

}
